import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "./BusinessGuide.module.scss";
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";
import Bener from "../../components/Bener/Bener";
import FixIcon from "../../components/FixIcon/FixIcon";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import page1 from "../../assets/BusinessGuide/BusinessGuide2/page1.jpg";

const BusinessGuide2 = () => {
  const menuContents = [
    { title: "분양일정", url: "/BusinessGuide/intro" },
    { title: "분양일정", url: "/BusinessGuide/plan" },
    { title: "선착순계약 서류안내", url: "/BusinessGuide/documents" },
  ];
  const [isScroll, setIsScroll] = useState(false);
  const [isImageVisible, setIsImageVisible] = useState(false); // isImageVisible 상태 추가
  const { pathname } = useLocation(); // 현재 경로를 가져옴
  const [isTextVisible, setIsTextVisible] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
  }, [pathname]); // pathname이 변경될 때마다 실행

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScroll(true);
      } else {
        setIsScroll(false);
      }

      // 이미지가 화면에 보이는지 체크 (예시)
      if (window.scrollY > 200) {
        // 예시: 스크롤이 200px 이상 내려가면 이미지 보이기
        setIsImageVisible(true);
      } else {
        setIsImageVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={styles.container}>
      <Helmet>
        {/* 기본 문자셋 및 모바일 최적화를 위한 meta 태그 */}
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <meta name="theme-color" content="#ffffff" />
        <meta name="robots" content="index, follow" />

        {/* SEO 최적화를 위한 메타 태그 */}
        <title>힐스테이트용인마크밸리 - 분양일정</title>
        <meta
          name="description"
          content="첨단 단지 설계와 다양한 입주 혜택이 돋보이는 현대건설의 계약, 서류 접수 및 분양 일정 등 상세 정보를 확인하고 최적의 주거와 투자 기회를 만나보세요.
			"
        />
        <meta
          name="keywords"
          content="힐스테이트용인마크밸리"
        />
        <link rel="canonical" href="https://www.blog-gratuit.com/BusinessGuide/plan" />

        {/* Open Graph - 소셜 미디어 공유 최적화 */}
        <meta property="og:title" content="힐스테이트용인마크밸리 - 분양일정" />
        <meta
          property="og:description"
          content="첨단 단지 설계와 다양한 입주 혜택이 돋보이는 현대건설의 계약, 서류 접수 및 분양 일정 등 상세 정보를 확인하고 최적의 주거와 투자 기회를 만나보세요.
			"
        />
        <meta
          property="og:image"
          content="https://www.blog-gratuit.com/Main1.png"
        />
        <meta property="og:url" content="https://www.blog-gratuit.com/BusinessGuide/plan" />
        <meta property="og:site_name" content="힐스테이트용인마크밸리" />

        {/* Twitter 카드 설정 */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="힐스테이트용인마크밸리 - 분양일정" />
        <meta
          name="twitter:description"
          content="첨단 단지 설계와 다양한 입주 혜택이 돋보이는 현대건설의 계약, 서류 접수 및 분양 일정 등 상세 정보를 확인하고 최적의 주거와 투자 기회를 만나보세요.
			"
        />
        <meta
          name="twitter:image"
          content="https://www.blog-gratuit.com/Main1.png"
        />
        <meta name="twitter:url" content="https://www.blog-gratuit.com/BusinessGuide/plan" />

        {/* 구조화된 데이터 (JSON-LD) - 검색엔진 이해도 향상 */}
        <script type="application/ld+json">
          {`
					{
						"@context": "https://schema.org",
						"@type": "WebPage",
						"name": "힐스테이트용인마크밸리 - 분양일정",
						"description": "첨단 단지 설계와 다양한 입주 혜택이 돋보이는 현대건설의 계약, 서류 접수 및 분양 일정 등 상세 정보를 확인하고 최적의 주거와 투자 기회를 만나보세요.
			
						",
						"url": "https://www.blog-gratuit.com/BusinessGuide/plan"
					}
					`}
        </script>
      </Helmet>

      <header>
        <Header isChanged={isScroll} />
      </header>
      <FixIcon />

      <Bener title="사업개요" />

      <MenuBar contents={menuContents} />

      <nav className={styles.screenReaderOnly}>
        <h2>힐스테이트용인마크밸리 분양일정</h2>
        <ul>
          {menuContents.map((menu, index) => (
            <li key={index}>
              <Link to={menu.url}>{menu.title}</Link>
            </li>
          ))}
        </ul>
      </nav>
      {/* <h1> 태그를 사용하여 페이지 제목 설정 (SEO 최적화) */}
      <section className={styles.screenReaderOnly}>
        <h1>힐스테이트용인마크밸리 - 분양일정</h1>
        <p>
          힐스테이트용인마크밸리의 사업 개요 및 개발 계획을 확인하세요. 혁신적인
          단지 설계, 편리한 인프라, 입주자 맞춤형 편의시설을 통해 최적의 주거
          환경을 제공합니다.
        </p>
      </section>

      <section
        className={`${styles.textBox} ${isTextVisible ? styles.active : ""} `}
      >
        <div>생활의 모든 것을 한걸음에</div>
        <div>현대건설, 반도체클러스터의 중심에 서다</div>
      </section>

      {/* 이미지에 isImageVisible 상태 적용 */}
      <img
        className={`${styles.image4} ${isImageVisible ? styles.visible : ""}`}
        src={page1}
        alt="힐스테이트용인마크밸리 분양일정안내-image1"
      />

      <Footer />
    </div>
  );
};

export default BusinessGuide2;
